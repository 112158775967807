@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.container {
    width: 100%;
    margin: 5px auto;
    padding: 10px;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    background: #EED7F4;
    border-radius: 10px;
}

.tabs button {
    border: none;
    border-left: 2px solid #652078;
    margin: 10px 0;
    color: #F37021;
    cursor: pointer;
    background: #EED7F4;
    padding: 20px;
    width: 100%;
    font-size: 20px;
}

button:first-child{
    border: none;
}

.tabs button:disabled {
    background: #652078;
    box-shadow: 0px 8px 16px #cd55ee4d;
    border-radius: 10px;
    color: #fff;
}

.title {
    color: black;
    font-size: medium;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1.44px;
}

.content {
    padding: 5px;
    background-color: #fff;
    font-weight: 300;
    line-height: 30px;
    font-size: 16px;
    text-align: justify;
}

.tabsIframe{
    height: 80vh;
    background-image: url('../../assets/loader.gif');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: block;
}

.hideTabsIframe {
    display: none;
}
