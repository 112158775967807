.rootAgri{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
}

select{
    width:100%;
    border-radius: 5px;
    border: 1px solid black;
    color: #000;
    text-align: left;
    padding: 10px 10px;
    font-size: 20px;
    cursor: pointer;
}

.inputHeader{
    padding: 10px;
}

.buttonContainer{
    display: flex;
    gap: 1em;
    margin-top: 1em;
}

.button{
    padding:1em 2em;
    background:#f46d19;
    border-radius:1px;
    cursor:pointer;
    color:#fff;
}

.resetButton{
    background: #d3d3d3;
    color:#000;
}