@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Roboto:wght@700&display=swap');

.wrapper{
    width:100vw;
    height:100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    background-color: #CEFCBA;
}

.heading{
    font-family: 'DM Sans', sans-serif;
}

.input-field{
    width: 330px;
    height: 50px;
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.input-field input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: hidden;
}

.newTabButton{
    width: 150px;
    height: 40px;
    background: #4CB050 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-family: 'DM Sans', sans-serif;
}