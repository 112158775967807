.root{
  width:100%;
  height: 5em;
  background: #652078;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo{
  color:#fff;
}