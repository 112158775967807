table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  table td,
  table th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  table tr:nth-child(even) {
    background-color: #fff;
  }
  
  table tr:hover {
    background-color: #ddd;
  }
  
  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    color: black;
  }
  